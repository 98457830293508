.App {
  text-align: center;
}
#getquote {
  padding: 10px;
  border-radius: 20px;
  font-size: 20px;
  background-color: #426113;
  color: white;
}
#modalS{
  position: fixed;
  top: 10%;
  left:40%
  
}

.modald{
  position: fixed;
  background-color: #73a030;
  height:fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  right: 20;
  left: 20;
  bottom: 20;
  top: 0;
  margin: 20px auto;
  padding: 20px;
}

.modal-icon{
  position: absolute;
  right:10px;
  top:10px
}

.image-gallery{
  width: 200px;
  height: auto;
}

#t{
  height: 200px;
  width: 200px;
  background-color: #426113;
  animation-name: t;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  position: relative;
}

#logo{
  /* width: 4em; */
 width: 150px;
 margin: 0%;

}
.head{
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes t {
  /* from {background-color: #426113;top:0;left:0}
  to {background-color: yellow;bottom: 0; right: 0;} */
  /* 0%   {background-color: red;}
  25%  {background-color: yellow;}
  50%  {background-color: blue;}
  100% {background-color: green;} */
  0%   {background-color:red; left:0px; top:0px;}
  25%  {background-color:yellow; left:200px; top:0px;}
  50%  {background-color:blue; left:200px; top:200px;}
  75%  {background-color:green; left:0px; top:200px;}
  100% {background-color:red; left:0px; top:0px;}
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery-item {
  width: 150px;
  cursor: pointer;
}

.gallery-thumbnail {
  width: 100%;
  height: auto;
}

.modal {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 200px;
  z-index: 1000;
  border-radius: 10px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.close-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff4c4c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
}

.close-button:hover {
  background-color: #ff1c1c;
}


#drop{
  color: white !important;
  background-color: #426113;

}

#drop:hover{
  color: white !important;
  background-color: #619119;
  
}

#dropT{
  color: white !important;
  /* background-color: #426113; */
}
p {
  margin: 0 !important;
  padding: 0;
}

#viewMore {
  background: none;
  border: 2px solid #426113;
  border-radius: 10px;
  padding: 10px;
}

#viewMore:hover {
  background: #426113;
  border: 2px solid white;
  border-radius: 10px;
  color: white;
}

#aboutCol {
  background-color: #426113;
}

#readMBtn {
  background-color: white;
  flex-grow: 1;
  flex: 1;
  margin-top: 10%;
  border: 1px solid white;
  border-radius: 10px;
}

#chatBox{
background-color: #73a030;
padding: 20px;
border-radius: 20px;
}

#btnContact {
  padding: 10px;
  margin: 20px;
  background-color: #426113;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-grow: 1;
  font-size: 20px;
  color: aliceblue;
  border-radius: 50px;
  border: 2px solid #426113;
  cursor: pointer;
}

#btnContact:hover {
  background-color: white;
  color: #426113;
  border: 2px solid #426113;
}

.card {
  width: 250px;
  height: 250px;
  overflow: hidden;
  background-color: #426113;
}
#cardImg {
  width: 250px;
  height: 200px;
}

#cardImg:hover {
  /* transition: transform 0.3s ease-in-out; */
  transform: scale(1.1);
  overflow: hidden;
}
/* p{
  overflow: hidden;
  z-index: 1000;
} */

#getquote:hover {
  background-color: white;
  color: #426113;
  border: #426113;
  overflow: hidden;
  padding: 10px;
  border-radius: 20px;
  font-size: 20px;
}
.pageTitle {
  color: #426113;
  font-size: 45px;
}

.pageTitleCore {
  color: white;
  font-size: 45px;
}

#contactTitle{
  font-size: 40px;
  color: #426113;
  font-weight: 500;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

#contactSubTitle{
  font-size: 22px;
  color: #426113;
  font-weight: 100;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.sadf{
  background-color: #9bdd3fcc;
}
.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;

  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  border-radius: 20px;
}

.flip-card1 {
  /* background-color: transparent; */
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  border-radius: 20px;
  width: 250px;
  height: 250px;
  overflow: hidden;
  background-color: #fff;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card1:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 20px;
  /* padding: 10px; */
  overflow: hidden;
  text-overflow: ellipsis;
}



/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: white;
  color: #426113;
}

/* Style the back side */
.flip-card-back {
  background-color: white;
  color: #426113;
  transform: rotateY(180deg);
}

#dm * {
  -webkit-tap-highlight-color: transparent;
}
.dmInner * {
  -webkit-font-smoothing: antialiased;
}
.dmRespDesignRow, .dmRespRow, .dmRespRow .dmRespColsWrapper {
  max-width: 960px;
  position: relative;
  margin: 0 auto;
  width: 100%;
}
.dmRespDesignRow, .dmRespRow {
  padding-top: 15px;
  padding-bottom: 15px;
}
* {
  scrollbar-arrow-color: #fff;
  scrollbar-track-color: #f2f2f2;
  scrollbar-face-color: silver;
  scrollbar-highlight-color: silver;
  scrollbar-3dlight-color: silver;
  scrollbar-shadow-color: silver;
  scrollbar-darkshadow-color: silver;
  scrollbar-width: 12px;
}
*, :after, :before {
  box-sizing: border-box;
}
user agent stylesheet
form {
  display: block;
  margin-top: 0em;
  unicode-bidi: isolate;
}
*#dm *.dmBody div.u_1528786047 {
  float: none !important;
  top: 0 !important;
  left: 0 !important;
  width: calc(100% - 0px) !important;
  position: relative !important;
  height: auto !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  max-width: 100% !important;
  padding-right: 0 !important;
  min-width: 25px !important;
  text-align: center !important;
}
#dm .dmGeoLocation.ed-version h3, #dm div.dmRespCol>*, div.dmPrettyList.ed-version h3 {
  line-height: normal;
}


.button_2 {
  --btn-text-direction: ltr;
  --btn-border-r-color: var(--btn-border-color);
  --btn-border-l-color: var(--btn-border-color);
  --btn-border-b-width: var(--btn-border-width);
  --btn-border-width: 2px;
  --btn-border-radius: 0px;
  --btn-border-t-width: var(--btn-border-width);
  --btn-border-tl-radius: var(--btn-border-radius);
  --btn-border-br-radius: var(--btn-border-radius);
  --btn-border-bl-radius: var(--btn-border-radius);
  --btn-bg-color: rgb(0, 41, 112);
  --btn-border-color: rgba(0, 0, 0, 0);
  --btn-border-tr-radius: var(--btn-border-radius);
  --btn-border-r-width: var(--btn-border-width);
  --btn-bg-image: none;
  --btn-border-b-color: var(--btn-border-color);
  --btn-border-l-width: var(--btn-border-width);
  --btn-border-t-color: var(--btn-border-color);
  --btn-text-align: center;
  --btn-text-color: rgb(255, 255, 255);
  --btn-text-decoration: none;
  --btn-text-font-weight: 700;
  --btn-icon-color: rgb(0, 41, 112);
  --btn-icon-fill: rgb(0, 41, 112);
  --btn-icon-wrpr-display: none;
  --btn-hover-border-b-color: var(--btn-hover-border-color);
  --btn-hover-bg: rgb(0, 41, 112);
  --btn-hover-border-t-color: var(--btn-hover-border-color);
  --btn-hover-border-r-color: var(--btn-hover-border-color);
  --btn-hover-border-l-color: var(--btn-hover-border-color);
  --btn-hover-border-color: rgba(0, 0, 0, 0);
  --btn-hover-text-color: rgb(255, 255, 255);
  --btn-hover-text-font-weight: var(--btn-text-font-weight);
  --btn-hover-text-decoration: var(--btn-text-decoration);
  --btn-hover-text-font-style: var(--btn-text-font-style);
}
#dm .dmRespCol div.dmNewParagraph, #dm .dmRestaurantMenu .menuItemBox, #dm .dmform .dmforminput label, #dm .ecwid *, #dm .ecwid .ec-store, #dm div.dm-title h4, #dm div.dmContent h4, #dm div.dmFooter h4, #dm div.dmInner, #dm div.dmInner .dmRespCol, .dmGeoLocation a span.dmGeoMLocItemDetails, .dmGeoLocation a span.dmGeoMLocItemTitle, .dmPhotoGallery .caption-text.caption-text, .dmPhotoGallery p.caption-text, .dmPrettyList .itemName, .dmPrettyList .listText, .dmRestaurantMenu .menuItemDesc, .dmRestaurantMenu .menuItemName, .dmRestaurantMenu .menuItemPrice, .map-popup-description {
  font-size: 18px;
}
#dm .dmRestaurantMenu .menuItemBox, #dm .dmform .dmforminput label, #dm .ecwid *, #dm div.dmInner, #dm div.dmInner .dmRespCol, .dmGeoLocation a span.dmGeoMLocItemDetails, .dmGeoLocation a span.dmGeoMLocItemTitle, .dmPhotoGallery p.caption-text, .dmPrettyList .itemName, .dmPrettyList .listText, .dmRestaurantMenu .menuItemDesc, .dmRestaurantMenu .menuItemName, .dmRestaurantMenu .menuItemPrice, .map-popup-description {
  color: #002970;
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-style: normal;
}
*#dm *.dmBody div.u_1786539939 {
  padding-top: 20px !important;
  padding-bottom: 100px !important;
  float: none !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  position: relative !important;
  height: auto !important;
  padding-left: 40px !important;
  max-width: none !important;
  padding-right: 40px !important;
  min-width: 0 !important;
  text-align: left !important;
}
.dmInner {
  font-size: 16px;
}
.dmOuter {
  word-wrap: break-word;
}
#dmRoot {
  text-decoration-skip-ink: none;
}

#videoText {
  position: absolute;
  top: 20%;
  right: 50px;
  left: 50px;
  padding: 20px;
}

#videoTextTitle {
  color: rgb(255, 255, 255);
  font-size: 80px;
  font-weight: 700;
  z-index: 1000;
  text-shadow: 2px 2px #426113;
}

.flip-card {
  margin: 20px;
 }

 @media only screen and (max-width: 600px) {
  #modalS {
    left: 10px;
    right: 10px;
  }


  #videoTextTitle {
    color: white;
    font-size: 26px;
    font-weight: 700;
    z-index: 1000;
    text-shadow: 2px 2px #426113;
  }

  #videoText {
    position: absolute;
    top: 40%;
    right: 50px;
    left: 50px;
    bottom: 50px;
    padding: 20px;
  }

  #getquote {
    padding: 5px;
    border-radius: 20px;
    font-size: 12px;
    background-color: #426113;
    color: white;
  }

  #readMBtn {
    background-color: white;
    flex-grow: 1;
    margin: 2%;
    border: 1px solid white;
    border-radius: 10px;
  }

  .flip-card {
    margin: 20px;
  }

  #btnContact {
    padding: 5px;
    margin: 10px;
    background-color: #426113;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-grow: 1;
    font-size: 15px;
    color: aliceblue;
    border-radius: 20px;
    border: 2px solid #426113;
    cursor: pointer;
  }

  .pageTitle {
    color: #426113;
    font-size: 30px;
  }

  #headT {
    display: none;
  }
}


